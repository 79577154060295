import React, { useState, useEffect } from "react";
import Content from "./Content.js";
import { Interstitial } from "../../global/Interstitial.js";
import { CSSTransition } from "react-transition-group";

const Quiz = (props) => {

  const startValues = {
    position: 0,
    showAnswer: false
  }
  const [quizState, updateQuizState] = useState(startValues);

  useEffect(() => {
    const interstitialLinks = document.querySelectorAll('.opens-interstitial');
    const interstitial = document.querySelector('.interstitial-container');
    const body = document.querySelector('body');
    interstitialLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        let url = e.target.href;
        interstitial.classList.remove('interstitial-hidden');
        body.classList.add('hide-scroll');
        let interstitialContinue = interstitial.querySelector('.interstitial-link.continue');
        interstitialContinue.href = url;
      })
    });
  }, [quizState])


  const Choices = (position) => {
    if (Content[quizState.position].multipleChoice) {
      const choices = Content[quizState.position].choices;
      return (
        <>
          {choices.map((content, index) =>
            <button
              className={`
                white bdr-plum 
                ${index === 0 || 2 ? 'multiple-choice-left' : ''}
              `}
              onClick={() => updateQuizState({
                position: quizState.position,
                showAnswer: true
              })}
              index={index}
              key={index}
            >
              {content}
            </button>
          )}
        </>
      )
    }
    else {
      return (
        <>
          <button
            className="white bdr-plum true"
            onClick={() => updateQuizState({
              position: quizState.position,
              showAnswer: true
            })}>
            True
          </button>
          <button
            className="white bdr-plum"
            onClick={() => updateQuizState({
              position: quizState.position,
              showAnswer: true
            })}>
            False
          </button>
        </>
      )
    }
  }

  const goToNext = () => {
    if (quizState.position !== Content.length - 1) {
      updateQuizState({
        position: quizState.position + 1,
        showAnswer: false
      })
    }
    else {
      updateQuizState({
        position: 0,
        showAnswer: false
      })
      props.hideQuiz();
    }
  }

  const handleClose = () => {
    if (quizState.showAnswer) {
      updateQuizState({
        position: quizState.position,
        showAnswer: false
      })

    }
    else {
      props.hideQuiz();
    }
  }

  return (
    <section className={`
        quiz container-fluid
        ${props.show ? 'show' : 'hide'}
      `}>

      {/*QUESTION*/}

      <CSSTransition
        in={!quizState.showAnswer}
        timeout={0}
        unmountOnExit
      >
        <div className="container">
          <div className="row">

            <div className="col layout">
              <img
                className="close-icon black"
                onClick={handleClose}
                src="/images/home/close-icon-black.svg"
                alt="close icon"
              />
              <div className="content">
                <div className="dots">
                  {Content.map((dot, index) =>
                    <span
                      className={`dot ${index === quizState.position ? 'active' : ''}`}
                      key={index}
                    />
                  )}
                </div>
                <div className={` 
                container-question
              `}>
                  <div className="statement">
                    {Content[quizState.position].statement}
                  </div>
                  <Choices />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      {/*ANSWER*/}

      <CSSTransition
        in={quizState.showAnswer}
        timeout={1000}
        unmountOnExit
      >
        <div className="container-fluid answer">
          <div className="container">
            <div className="row">
              <div className="col layout bkg-plum-gradient">
                <img
                  className="close-icon white"
                  onClick={handleClose}
                  src="/images/global/close-icon-white.svg"
                  alt="close icon"
                />
                <div className="content">
                  <div className={`
                container-answer 
                ${quizState.showAnswer ? 'show' : 'hide'}
              `}>
                    ANSWER: <br />
                    <div className="answer">
                      <span>
                        {Content[quizState.position].answer}
                      </span>
                    </div>
                    <div className="description">
                      {Content[quizState.position].description}
                    </div>
                    <a className="link" href={Content[quizState.position].linkUrl}>
                      {Content[quizState.position].linkText}
                    </a>
                    <a 
                      target="_blank"
                      className="button white font-avenir-heavy opens-interstitial" 
                      data-interstitial-type="button white font-avenir-heavy opens-interstitial" 
                      href="https://aidsvu.org/services/#/testing"
                      >
                      FIND HIV TESTING NEAR YOU
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <p
                  className={`next-action ${quizState.showAnswer ? 'show' : 'hide'}`}
                  onClick={() => goToNext()}>
                  {quizState.position !== Content.length - 1 ? 'NEXT QUESTION' : 'CLOSE'}
                  <img src="/images/global/triangle-blue.svg" alt="blue play icon" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <Interstitial />
    </section>
  )
}

export default Quiz