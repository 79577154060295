import React from "React";

const Content = [

  // QUESTION 1
  {
    multipleChoice: false,
    statement:
      `Only about 30% of adults are recommended to 
      get tested for HIV in their lifetime.`,
    answer: "FALSE",
    description:
      <p>
        It's recommended that EVERYONE ages 13 to 64 gets
        tested for HIV at least once in their lifetime.
      </p>,
    hasLink: true,
    linkText: "HIV Testing Is for Everyone",
    linkUrl: "hiv-testing-guidelines"
  },

  // QUESTION 2
  {
    multipleChoice: true,
    choices: ["Nearly 15%", "Nearly 40%", "Nearly 22%", "Nearly 80%"],
    statement:
      `What percentage of new HIV infections are transmitted by people 
      who don't know they have HIV or are not in care?`,
    answer: "NEARLY 80%",
    description:
      <>
        <p>
          Knowing your HIV status can help prevent you from giving the virus to your partner.
        </p>
        <p>
          That's why it's so important to get tested regularly if you are sexually active,
          so you can take the appropriate next steps.
        </p>
        <p className="footnote">
          *According to 2016 data.
        </p>
      </>,
    hasLink: true,
    linkText: "When to Get Tested for HIV",
    linkUrl: "/hiv-testing-guidelines"
  },

  // QUESTION 3
  {
    multipleChoice: false,
    statement:
      `Getting tested for HIV will cost a lot of money and your results won't be private.`,
    answer: "FALSE",
    description:
      <p>
        These are common myths about HIV testing, but the truth is getting tested for
        HIV is confidential by law and may be free in some instances.
      </p>,
    hasLink: true,
    linkText: "Taking an HIV Test: What to Expect",
    linkUrl: "/how-to-get-hiv-tested"
  },

  // QUESTION 4
  {
    multipleChoice: false,
    statement:
      `HIV is a death sentence.`,
    answer: "FALSE",
    description:
      <p>
        While there is no cure for HIV, there are medicines available to treat HIV.
        People living with HIV should talk to a doctor. The sooner you start
        treatment and stick with it, the less damage HIV can do to your body,
        helping you live a longer, healthier life.
      </p>,
    hasLink: true,
    linkText: "What Happens After an HIV Test",
    linkUrl: "after-an-hiv-test"
  },

  // QUESTION 5
  {
    multipleChoice: true,
    choices: ["6 months", "3 years", "18 months", "5 years"],
    statement:
      `1 in 2 people living with HIV had the virus for at least how long before knowing it?`,
    answer:
      <>
        3 YEARS<sup>&dagger;</sup>
      </>,
    description:
      <>
        <p>
          EVERYONE ages 13 to 64 should get tested for HIV at least once in
          their life—and should get retested more often if they're sexually active.
        </p>
        <p className="footnote">
          <sup>&dagger;</sup>According to 2015 data.
        </p>
      </>,
    hasLink: true,
    linkText: "Who Should Get Tested and When",
    linkUrl: "/hiv-testing-guidelines"
  },

  // QUESTION 6
  {
    multipleChoice: false,
    statement:
      <>
        Besides testing, there are lots of ways to tell
        if <span className="d-inline-block">someone has HIV.</span>
      </>,
    answer: "FALSE",
    description:
      <p>
        The ONLY way to know if you are living with HIV is to get tested.
      </p>,
    hasLink: true,
    linkText: "",
    linkUrl: ""
  }
]

export default Content;