import * as React from "react";

// Hero Overlay

export const HeroMedia = () => {
  return (
    <>
      <video className="media d-none d-md-block" loop muted autoPlay>
        <source src= "/video/Spanish_Homepage_Hero_Desktop.mp4" type="video/mp4" />
      </video>
      <img
        className="media d-block d-md-none"
        src="/images/esp/Spanish_Homepage_Hero_Mobile.png"
        alt="Grupo de amigos hablando mientras comen" />
    </>
  )
}

export const HeroCopy = () => {
  return (
    <>
      <p className="hero-copy">
        PRESIONA
        <img className="play-icon" src="/images/global/Press_Play_Hero_icon.svg" alt="Click for Homepage"/>
      </p>
      <h1 className="esp">
        HÁGASE LA PRUEBA, CONOZCA SU ESTATUS DE VIH
      </h1>
    </>
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      <p className="esp">
        Está en camino de avanzar. Una vez que conozca su estatus de VIH, hay medicamentos disponibles para tratar el VIH y opciones para ayudar a prevenirlo. Hable con un médico sobre lo que es adecuado para usted.
      </p>
      <div className="esp-links">
        {/* First link-note group */}
        <div className="esp-link-note-container">
          <a
            target="_blank"
            className="button white esp-link esp-link-postal font-avenir-heavy opens-interstitial"
            data-interstitial-type='aidsvu-esp'
            href="https://aidsvu.org/services/#/testing">
            ENCUENTRE DÓNDE HACERSE LA PRUEBA DEL VIH ACERCA DE SU CÓDIGO POSTAL
          </a>
          <p className="esp-link-note">No todas las ciudades o estados ofrecen <br/>pruebas de VIH gratuitas.</p>
        </div>
        {/* Second link-note group */}
        <div className="esp-link-note-container">
          <a
            target="_blank"
            className="transparent esp-link esp-link-hogar font-avenir-heavy"
            href="https://together.takemehome.org/espanol?utm_source=Gilead&utm_medium=Partner-Organic-Spanish&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion">
            HÁGASE LA PRUEBA EN SU HOGAR
          </a>
          <p className="esp-link-note text-center">Se encuentran disponibles prácticas pruebas en el hogar. Consulte&nbsp;a un médico para confirmar los resultados de su prueba: <br className="d-none d-lg-block" />la&nbsp;prueba en el hogar no puede evaluarlo todo.</p>
        </div>
      </div>
    </>
  )
}
