import  React, { useState, useEffect } from "react";
import '../assets/sass/app.scss';
import Quiz from '../components/esp/Quiz/Index.js'
import Layout from '../components/global/Layout.js';
import { HeroMedia, HeroCopy, Cta} from '../components/esp/HeroContent.js'
import Datos from '../components/esp/datos.js';
import WhoShouldGetTested from '../components/esp/WhoShouldGetTested.js'
import WhatToExpect from '../components/esp/WhatToExpect.js'
import WhatHappensAfter from '../components/esp/WhatHappensAfter.js'
import Prevention from "../components/esp/Prevention.js";
import scrollToElement from '../components/global/ScrollToElement';
import Header from '../components/global/Header.js';

const Esp = () => {

  const [quizOpen, openQuiz ] = useState(false);

  // Lock body scroll beneath Quiz overlay

  useEffect(()=>{
    const toAdd = quizOpen ? 'overflow-hidden' : 'overflow-auto';
    const toRemove = quizOpen ? 'overflow-auto' : 'overflow-hidden';
    document.body.classList.add(toAdd);
    document.body.classList.remove(toRemove)
  },[quizOpen]);

  // Todo: EXPAND CLICKABLE AREA for button links

  return (
    <>
      <Header page="esp" scrollToElement={scrollToElement} />
      <Quiz show={quizOpen} hideQuiz={()=>{openQuiz(false)}}/>
      <Layout 
        page="esp" 
        heroMedia={<HeroMedia/>}
        heroCopy={<HeroCopy/>}
        noSocial="true"
        CTA= {
          <Cta openQuiz={()=>{openQuiz(true);}}/>
        }
        
      >
        <Datos/>
        <WhoShouldGetTested/>
        <WhatToExpect/>
        <WhatHappensAfter/>
        <Prevention />
      </Layout>
    </>
  )
}

export default Esp
