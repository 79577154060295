import React, { useState } from "react";
import Bar from '../global/Bar.js';

const Datos = () => {
  return (
    <section className="container-fluid datos bkg-white">
      <div className="container px-0">
        <div className="row">
          <div className="col">
            <h2 className="datos-headline">
              Datos sobre el VIH
            </h2>
            <div className="datos-cards-container">
              <div className="datos-card datos-card-1 bkg-blue-gradient">
                <h2 className="datos-card-header">Aproximadamente
                  <br /><b>1 de cada 6</b> hispanos/<br/>latinoamericanos viviendo con VIH no lo sabían.*</h2>
                <p className="datos-card-note">*De acuerdo con los datos de 2019. Fuente: CDC.</p>
              </div>
              <div className="datos-card datos-card-2 bkg-plum-gradient">
                <h2 className="datos-card-header">Aproximadamente <b>1 de cada 5</b> hombres latinoamericanos que tienen relaciones sexuales con hombres pueden ser diagnosticados con VIH en algún momento de su vida.<sup>†</sup></h2>
                <p className="datos-card-note"><sup>†</sup>Si las tasas de VIH persisten de acuerdo con los datos de 2010–2016. Fuente: CDC.</p>
              </div>
            </div>
            <a href="https://www.cdc.gov/hiv/spanish/basics/index.html" target="_blank" className="btn rounded-pill conozca-mas">
              CONOZCA MÁS DATOS <br className="d-md-none" />SOBRE EL VIH
            </a>
          </div>
        </div>
        <Bar classes="blue" />
      </div>
    </section>
  )
}

export default Datos;

