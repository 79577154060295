import * as React from "react";

const Prevention = () => {
  return (
    <>
      <section className="prevention-esp container-fluid bkg-gray-gradient">
        <div className="container px-0">
          <p className="copy copy-top">
            Aunque no existe cura para el VIH, existen medicamentos modernos
            para tratar el VIH y también opciones para prevenirlo. Hable con un
            médico sobre las opciones adecuadas para usted.
          </p>
          <div className="row callouts">
            <div className="col-12 container-icon icon-top mb-5">
              <div className="content flex-column flex-lg-row offset-md-1 col-md-11 justify-content-md-end">
                <img
                  className="icon shield d-none d-md-block"
                  src="/images/after-an-hiv-test/Help-Prevent-HIV-icon.svg"
                  alt="Find an HIV Testing Location"
                />
                <img
                  className="icon d-block d-md-none"
                  src="/images/after-an-hiv-test/Help-Prevent-HIV-icon-mobile.svg"
                  alt="Test at Home"
                />
                <div className="text offset-lg-1 col-lg-8">
                  <h3>Cómo prevenir el VIH</h3>
                  <p className="description">
                    Si está a riesgo de contraer VIH, hay medicamentos que puede
                    tomar antes de exponerse y que, cuando se usan según las
                    indicaciones, pueden ayudarlo a protegerse contra el VIH.
                    Esto es conocido como profilaxis previa a la exposición <span className="text-nowrap">(pre-exposure prophylaxis, PrEP).</span>
                  </p>
                  <p className="description mb-4">
                    Hable con un médico para ver si es lo correcto para usted y
                    haga haga clic abajo para obtener información sobre este y
                    más métodos de prev ención.
                  </p>
                  <a
                    target="_blank"
                    className="prevention-button transparent thin"
                    href="https://www.helpstopthevirus.com/hiv-prevention"
                  >
                    OBTENGA MÁS INFORMACIÓN
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 container-icon icon-bottom mb-5">
              <div className="content flex-column flex-lg-row offset-md-1 col-md-11 justify-content-md-end">
                <img
                  className="icon briefcase d-none d-md-block"
                  src="../../images/after-an-hiv-test/Help-Treat-HIV-icon.svg"
                  alt="Test at Home"
                />
                <img
                  className="icon icon-bottom d-block d-md-none"
                  src="/images/after-an-hiv-test/Help-Treat-HIV-icon-mobile.svg"
                  alt="Find an HIV Testing Location"
                />
                <div className="text offset-lg-1 col-lg-8">
                  <h3>Cómo tratar el VIH</h3>
                  <p className="description mb-4">
                    Aunque no hay cura, el tratamiento y la atención médica
                    adecueda ayudarlo a llevar una vida más saludable. Si tiene
                    VIH, los medicamentos de tratamiento que se toman como son
                    recetados pueden ayudar a reducir la cantidad de virus en la
                    sangre a un nivel tan bajo que no se puede medir con un
                    análisis de laboratorio. Si se mantiene de esta manera, no
                    puede transmitir el VIH a otras personas a través del sexo.
                  </p>
                  <a
                    target="_blank"
                    className="prevention-button transparent thin"
                    href="https://www.helpstopthevirus.com/hiv-treatment"
                  >
                    OBTENGA MÁS INFORMACIÓN
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className="copy copy-bottom mt-md-n5">
            Asegurándose de su estatus de VIH a través de pruebas de VIH de
            rutina es una parte importante de su cuidado personal. Cuando
            conozca su estatus, puede hablar con un médico acerca atención para
            el VIH o las opciones de prevención adecuadas para usted.
          </p>
        </div>
      </section>
    </>
  );
};

export default Prevention;
