import * as React from 'react';

const WhatToExpect = () => {
  return (
    <>
      <section className="container-fluid what-to-expect-esp bkg-plum">
        <div className="container col-lg-10 px-0">
          <div className="row">
            <div className="col ">
              <h2>
                Dónde hacerse la prueba y qué esperar
              </h2>
              <p className="summary">
                Haciendose la prueba del VIH es lo correcto. Pero es natural sentirse nervioso, asustado o abrumado por hacerse las pruebas. Solo recuerde que hay confianza en conocer su estatus de VIH, independientemente de los resultados de su prueba.
              </p>
              <p className="summary">
              La mayoría de los planes de seguro médico cubren el costo de la prueba del VIH. Si no tiene seguro médico, hay pruebas de VIH gratuitas disponibles.
              </p>
            </div>
          </div>
          <div className="row callouts justify-content-center">

            {/* First callout */}
            <div className="col-12 col-lg-4 container-icon location">
              <img className="icon" src='/images/esp/Location_icon_es.svg' alt="Buscar un sitio de pruebas de VIH" />
              <h2 className="icon-title">Hágase la prueba del VIH</h2>
              <p className="icon-subtext">Encuentre un lugar para hacerse la prueba del VIH cercano a usted. Es confidencial y puede ser gratis.</p>
              <a 
                target="_blank"
                className="icon-button icon-button-location button white short opens-interstitial"
                data-interstitial-type='aidsvu-esp'
                href="https://aidsvu.org/services/#/testing">
                ENCUENTRE DÓNDE HACERSE LA PRUEBA DEL VIH ACERCA DE SU CÓDIGO POSTAL
              </a>
              <p className="extra-small">
                No todas las ciudades o estados ofrecen pruebas de VIH gratuitas.
              </p>
            </div>

            {/* Second callout */}
            <div className="col-12 col-lg-4 container-icon">
              <img className="icon" src='/images/esp/Home_icon_es.svg' alt="Hacerse la prueba en el hogar" />
              <h2 className="icon-title">¿Prefiere hacerse la prueba en su casa?</h2>
              <p className="icon-subtext">Se encuentran disponibles pruebas en el hogar. Consulte a un médico para confirmar los resultados de sus pruebas&#8212;las pruebas en el hogar no pueden detectar todo.</p>
              <a 
                target="_blank"
                className="icon-button icon-button-casa button transparent bdr-white thin short" 
                href="https://together.takemehome.org/espanol?utm_source=Gilead&utm_medium=Partner-Organic-Spanish&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion">
                HÁGASE LA PRUEBA <br/>EN SU HOGAR
              </a>
            </div>

          </div>
        </div>
      </section>
    </>
  )

}

export default WhatToExpect;