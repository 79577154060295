import * as React from 'react';
import Bar from '../global/Bar.js';

const WhoShouldGetTested = () => {
  return (
    <>
      <section className="container-fluid who-should-get-tested bkg-gray-gradient">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <img 
                className="callout w-100 d-none d-md-block" 
                src='/images/esp/Who_Should_Get_Tested_Callout.png' 
                alt="Dos hombres mirándose junto a un lago" 
              />
              <img 
                className="callout w-100 d-block d-md-none" 
                src="/images/esp/Who_Should_Get_Tested_Callout_mobile.png" 
                alt="Dos hombres mirándose junto a un lago" 
              />
              <h2 className="mb-2">
                ¿Quién debe<br className="d-block d-md-none" /> hacerse la prueba? 
                <br />
                <b>Todos.</b>
              </h2>
              <p className="summary">
              Todas las personas entre 13 y 64 años deben hacerse la prueba del VIH al menos una vez, porque las pruebas de rutina son una parte importante y normal del cuidado personal. Pero para las personas con mayor riesgo, es posible que una vez no sea suficiente. Siéntase orgulloso de conocer su estatus.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default WhoShouldGetTested;