import * as React from 'react';
import Bar from '../global/Bar.js';

const WhatHappensAfter = () => {
  return (
    <>
      <section className="container-fluid who-should-get-tested bkg-gray-gradient">
        <div className="container px-0">
          <div className="row">
            <div className="col">
            <Bar classes="orange space-below" />
              <img 
                className="callout w-100 d-none d-md-block" 
                src='/images/esp/After_Testing_Callout.png' 
                alt="Mujer sentada delante de una casa" 
              />
              <img 
                className="callout w-100 d-block d-md-none" 
                src="/images/esp/After_Testing_Callout_mobile.png" 
                alt="Mujer sentada delante de una casa" 
              />
              <h2 className="mb-2">
              Qué sucede después de una prueba de VIH
              </h2>
              <p className="summary">
              Independientemente del resultado de la prueba, existen opciones 
              para usted. Si los resultados de su prueba son positivos, es 
              posible que tengas muchas preguntas y emociones. Es importante 
              saber que no está solo. Hay muchos recursos para usted; encuentre 
              algunos <a className="aqui-link opens-interstitial" data-interstitial-type='aidsvu-esp' href="https://aidsvu.org/services">aquí</a>. 
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default WhatHappensAfter;